@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.LogoList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;

    @mixin above $small {
      flex: 1 1 25%;
      max-width: 25%;
    }

    @mixin above $medium {
      flex: 1;
    }
  }
}