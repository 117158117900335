.ServicesForm {
  textarea {
    height: 248px;
    resize: vertical;
  }

  button {
    float: right;
  }

  :global(.u-grid-col) {
    margin-bottom: 0;
  }

  :global(.Fieldset) {
    margin: 20px 0 !important;
  }

  :global(.FormRow) {
    padding: 0 !important;
  }
}